.custom-scrollbar::-webkit-scrollbar {
  opacity: calc(0);
  border-top-right-radius: 0.5rem /* 8px */;
  border-bottom-right-radius: 0.5rem /* 8px */;
  height: 6px;
}

.scrollbar-color-primary::-webkit-scrollbar-thumb {
  background-color: #a68cfd;
  border-radius: 0.5rem;
}
