.custom-scrollbar::-webkit-scrollbar {
  opacity: calc(0);
  border-top-right-radius: 0.5rem /* 8px */;
  border-bottom-right-radius: 0.5rem /* 8px */;
  width: 8px;
}

.scrollbar-color-gray::-webkit-scrollbar-thumb {
  background-color: #a3a3a3;
  border-radius: 0.5rem;
}

.scrollbar-color-error::-webkit-scrollbar-thumb {
  background-color: #ef706f;
  border-radius: 0.5rem;
}

.scrollbar-color-success::-webkit-scrollbar-thumb {
  background-color: #54d397;
  border-radius: 0.5rem;
}
