@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: "Inter";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* GLOBAL ANIMATIONS */

.animation-fade-in {
  animation: 0.15s cubic-bezier(0.445, 0.05, 0.55, 0.95) fade-in-animation;
}

@keyframes fade-in-animation {
  from {
    opacity: 0;
  }
  to {
    opacity: 100;
  }
}

/*To create pseudo-class */
.hexagon {
  clip-path: polygon(25% 0, 75% 0%, 85% 50%, 75% 100%, 25% 100%, 15% 50%);
}
