progress[value]::-webkit-progress-bar {
  background-color: #eee;
  border-radius: 55px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25) inset;
}

progress[value]::-webkit-progress-value {
  background-color: #a68cfd;
  border-radius: 55px;
}
